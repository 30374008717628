$(document).ready(function(){
    $('.promotion-slider').slick({
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000
    });

    $('.news-slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.product-list-slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        prevArrow: '<button class="slick-prev"><</button>',
        nextArrow: '<button class="slick-next">></button>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.offer__case-slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    });


    $('.burger').click(() => {
        $('.right-menu').toggleClass('right-menu__active');
        $('.menu__bg').css('display', 'block');
    })
    $('.right-menu__btn, .menu__bg').click(() => {
        $('.right-menu').removeClass('right-menu__active');
        $('.menu__bg').css('display', 'none');
    });


    $('.footer__accordion-item').on('click', accToggle);

    function accToggle() {
        $('.footer__accordion').not(this).find('.footer__accordion-item').removeClass('active');
        $(this).toggleClass('active');
    }


    $(window).on('load scroll', function () {
        const top = $(window).scrollTop();
        const menu = $('.bottom-line');
        if (top > 180) {
            menu.addClass('bottom-line-fixed');
        } else {
            menu.removeClass('bottom-line-fixed');
        }
    });

    $('.phoneMask').mask('+389999999999');

    function testPhone(phone) {
        return /^0[0-9]{9}$/.test(phone);
    }

    $('#commercialForm').submit(function () {
        const name = $('#commercialForm').find('input[name="username"]').val();
        const phone = $('#commercialForm').find('.phoneMask').val().slice(3);
        const type = $('#commercialForm').find('#commercialFormType').val();
        const subject = 'Форма "Скачать коммерческое предложение"';
        const message = `<p>Имя: ${name}</p>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=${subject}`,
                success: (res) => {
                    if (res) {
                        const link = document.createElement('a');
                        link.setAttribute('href', `/docs/Презентація_Інтернет_та_Послуги_Westelecom_${type ? type : 'ukr'}.pdf`);
                        link.setAttribute('download','download');
                        link.click();

                        $(this).trigger('reset');

                        $('#successOfferModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#failledModal').modal('show');
                }
            });
        }

        return false;
    });

    $('body').on('click', '.openFormBtn', function() {
        const text = $(this).data('text');
        if (text) {
            $('#customData').val(`<p>Примечание: ${text}</p>`);
        }
        $('#sendModal').find('.modal__send-item-res').text('').text(text);
        $('#sendModal').modal('show');
    });

    $('#sendModalForm, #sendModalFormHome').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const subject = $('#sendModal .modal__send-title').text();
        const comment = $(this).find('textarea[name="comment"]').val();
        const data = $(this).find('#customData').val();
        const message = `<p>Имя: ${name}</p><p>Комментарий: ${comment}</p>` + data;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[subject]=${subject}&Messages[message]=${message}`,
                success: (res) => {
                    $('#sendModal').modal('hide');
                    if (res) {
                        $(this).trigger('reset');
                        if ($(this).attr('id') === 'sendModalFormHome') {
                            $('#homeSuccessModal').modal('show');
                        } else {
                            $('#successModal').modal('show');
                        }
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    $('#howToConnectForm').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const subject = $('#howToConnectModal .modal__send-title').text();
        const comment = $(this).find('textarea[name="comment"]').val();
        const message = `<p>Имя: ${name}</p>` + `<p>${comment}</p>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[subject]=${subject}&Messages[message]=${message}`,
                success: (res) => {
                    $('#howToConnectModal').modal('hide');
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });


    $('#messageToDirectorForm').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const subject = $('#messageToDirector .modal__send-title').text();
        const comment = $(this).find('textarea[name="comment"]').val();
        const message = `<p>Имя: ${name}</p>` + `<p>${comment}</p>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[subject]=${subject}&Messages[message]=${message}`,
                success: (res) => {
                    $('#messageToDirector').modal('hide');
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });



    $('#formHelp').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const message = `<p>Имя: ${name}</p>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Форма обратной связи`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    $('#formOrder').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const email = $(this).find('input[name="email"]').val();
        const message = `<ul><li>Имя: ${name}</li><li>Email: ${email}</li></ul>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Форма обратной связи`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    $('#formConsult').submit(function () {
        const name = $(this).find('input[name="name"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const email = $(this).find('input[name="email"]').val();
        const message = `<ul><li>Имя: ${name}</li><li>Email: ${email}</li></ul>`;

        if (testPhone(phone) && name) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Консультация с инженером`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#sendModal').modal('hide');
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    let data_month;

    $('#pay1form').submit(function () {
        $('#errorNumdogovor').text('');
        const numdogovor = $('#numdogovor').val();
        $.ajax({
            method: 'POST',
            url: '/site/recommended-payment',
            data: `numdogovor=${numdogovor}`,
            beforeSend: function() {
                $('.lds-spinner').css('display', 'block');
                $('#moveForvard').css('display', 'none');
            },
            success: (res) => {
                $('.lds-spinner').css('display', 'none');
                $('#moveForvard').css('display', 'block');

                $('#numdogovor2').val(numdogovor);

                data_month = JSON.parse(res);

                $('#month1').prop('checked', true);
                $('#summ').val(data_month[1]);

                payShow();
            },
            error: (error) => {
                $('.lds-spinner').css('display', 'none');
                $('#moveForvard').css('display', 'block');

                const errorText = error.responseText.split(':')[1];
                $('#errorNumdogovor').text(errorText);
                setTimeout(() => {
                    $('#errorNumdogovor').text('');
                }, 3000);
            }
        });
        return false;
    });

    $('#payForm').submit(function () {
        const numdogovor = $('#numdogovor').val();
        const sum = +$('#paySum').val();
        if (sum >= 1) {
            $.ajax({
                method: 'POST',
                url: '/site/recommended-payment',
                data: `numdogovor=${numdogovor}`,
                beforeSend: function() {
                    $('.lds-spinner').css('display', 'block');
                    $('.pay-btn').css('display', 'none');
                },
                success: (res) => {
                    const data = JSON.parse(res)
                    $('#payNumdogovorItem').text(numdogovor);
                    $('#payFioItem').text(data.fio);
                    $('#paySumItem').text(sum);
                    $('.pay-btn-pay').attr('href', `https://www.ipay.ua/ua/charger?bill_id=92&acc=${numdogovor}&invoice=${sum}`);

                    $('.lds-spinner').css('display', 'none');
                    $('.pay-btn').css('display', 'block');

                    $('.pay-front').css('transform', 'rotateY(180deg)');
                    $('.pay-back').css('transform', 'rotateY(360deg)');
                },
                error: (error) => {
                    $('.lds-spinner').css('display', 'none');
                    $('.pay-btn').css('display', 'block');

                    $('.pay-error-numdogovor').show();
                    setTimeout(() => {
                        $('.pay-error-numdogovor').hide();
                    }, 3000);
                }
            });
        }
        return false;
    });

    $('.pay-btn-back').click(function () {
        $('.pay-front').css('transform', 'rotateY(0deg)');
        $('.pay-back').css('transform', 'rotateY(180deg)');
        $('#numdogovor').val('');
        $('#paySum').val('');
        $('#payNumdogovorItem').text('');
        $('#payFioItem').text('');
        $('#paySumItem').text('');
        $('.pay-btn-pay').attr('href', '');
    });

    $('#paySum').on('keyup', function(value) {
        this.value = this.value.replace(/[^0-9]/g, '');
    });

    $('input[name="number_month"]').change(() => {
        const month = $('input[name="number_month"]:checked').val();
        if (data_month) {
            $('#summ').val(data_month[month]);
        }
    });

    $('#pay2form').submit(function() {
        const id = $('#choosePlacemarket').val();
        const system = systems[id];
        const numdogovor = $('#numdogovor2').val();
        const summ = $('#summ').val();
        if (system) {
            let link = `${system[0]}${system[3]}=${numdogovor}&${system[4]}=${summ}`;
            window.open(link);
        }
        return false;
    });

    function payShow() {
        $('.front').css('transform', 'rotateY(180deg)');
        $('.back').css('transform', 'rotateY(360deg)');
        const height = $('.back .options-payment').outerHeight();
        $('.form-card-payment').css('height', height + 40);
    }

    function payHide() {
        const height = $('.front .options-payment').outerHeight();
        setTimeout(() => {
            $('.form-card-payment').css('height', height + 40);
        }, 500);

        $('.front').css('transform', 'rotateY(0deg)');
        $('.back').css('transform', 'rotateY(180deg)');
    }

    $('#moveBack').on('click', function () {
        payHide();
    });

    $('#summ, #numdogovor').on('input', function() {
        if (this.value.match(/[^0-9\.]/g)) {
            this.value = this.value.replace(/[^0-9\.]/g, '');
        }
    });


    // $('.check_with_select').change(function () {
    //
    //     const SELECT = $(this).parent().next();
    //     console.log(SELECT)
    //     if ($(this).is(':checked') == true) {
    //         console.log('w')
    //         SELECT.prop('disabled', false);
    //     } else {
    //         console.log('a')
    //         SELECT.prop('disabled', false);
    //         SELECT.select2({disabled:true});
    //     }
    // })

    $('.alert__close').click(function () {
        $('.alert-line').hide()
    })

    $('.right-stock__button').click(function () {
        $('.dropdown-menu__stock').toggleClass('open')
    })
    $(document).mouseup(function (e) {
        let dropDown = $(".dropdown-menu__stock");
        if (dropDown.has(e.target).length === 0){
            dropDown.removeClass('open');
        }
    });

    $('#btnRequisites').click(function() {
        const firm = cities_firms[$('#requisitesSelect').val()];
        if (firm == 2) {
            $('.requisites_treid').show();
        } else {
            $('.requisites_lekol').show();
        }

        $('.front_requisites').css('transform', 'rotateY(180deg)');
        $('.back_requisites').css('transform', 'rotateY(360deg)');
        const height = $('.back_requisites .options-payment').outerHeight();
        $('.requisites .form-card').css('height', height + 30);
    });

    $('#moveBackRequisites').click(function() {
        $('.requisites-text').hide();
        const height = $('.front_requisites .options-payment').outerHeight();
        setTimeout(() => {
            $('.requisites .form-card').css('height', height + 30);
        }, 500);

        $('.front_requisites').css('transform', 'rotateY(0deg)');
        $('.back_requisites').css('transform', 'rotateY(180deg)');
    });

    function startTimer() {
        const interval = setInterval(() => {
            let milliseconds = +$('.milliseconds').text();

            if (milliseconds == 0) {
                milliseconds = 99;
                $('.milliseconds').text(milliseconds);

                let seconds = +$('.seconds').text();
                seconds--;
                if (seconds > 9) {
                    $('.seconds').text(seconds);
                } else {
                    $('.seconds').text('0' + seconds);
                    if (seconds == 0) {
                        clearInterval(interval);
                        $('.milliseconds').text('00');
                        return;
                    }
                }
            } else {
                milliseconds--;
                if (milliseconds < 10) {
                    $('.milliseconds').text('0' + milliseconds);
                } else {
                    $('.milliseconds').text(milliseconds);
                }
            }
        }, 10);
    }

    $('#callForm').submit(function() {
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        if (testPhone(phone)) {
            $.ajax({
                method: 'POST',
                url: '/site/call',
                data: `phone=${phone}`,
                beforeSend: function() {
                    startTimer();
                    $('.callInput, .callSubmit').hide();
                    $('#callForm').css('justify-content', 'center');
                    $('#callTimer').css('margin', 0);
                    $('.h3-call1').hide();
                    $('.h3-call2').show();
                }
            });
        }
        return false;
    });

    $('.feedback-question').click(function () {
        const textarea = $('.feedback-textarea');
        const currentValue = textarea.val();
        const newValue = currentValue ? `${currentValue} \n${$(this).text()}` : $(this).text();
        textarea.val(newValue).animate({ scrollTop : textarea[0].scrollHeight - textarea[0].clientHeight }, 100);
    });

    $('#feedback-form').submit(function () {
        const phone = $(this).find('input[name="phone"]').val();
        const message = $(this).find('textarea[name="message"]').val();

        if (phone && message) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Feedback`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#successModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    $('#confirmForm').submit(function () {
        const city = $('#city').val();
        const street = $('#street').val();
        const house = $('#house').val();
        const entrance = $('#entrance').val();
        const floor = $('#floor').val();
        const flat = $('#flat').val();
        const internet = $('#internet').val();
        const router = $('input[name=router]:checked', '#confirmForm').val();
        const tv = $('#withoutTV').is(':checked') ? 'Нет' : $('#tv').val();
        const ip = +$('input[name=ip]:checked', '#confirmForm').val() ? $('#numberIP').val() : 'Нет';
        const name = $('#name').val();
        const phone = $('#phone').val().slice(3);
        const addPhone = $('#addPhone').val();
        const cid = $('#cid').val();

        const message = `<table>
                            <tr><td colspan="2"><a href="http://crm.westele.com.ua/manager/customer/view?id=${cid}">Ссылка на карточку клиента</a></td></tr>
                            <tr><td width="200px">ФИО</td><td>${name}</td></tr>
                            <tr><td>Дополнительный телефон</td><td>${addPhone}</td></tr>
                            <tr><td>Город</td><td>${city}</td></tr>
                            <tr><td>Улица</td><td>${street}</td></tr>
                            <tr><td>Дом</td><td>${house}</td></tr>
                            <tr><td>Подъезд</td><td>${entrance}</td></tr>
                            <tr><td>Этаж</td><td>${floor}</td></tr>
                            <tr><td>Квартира</td><td>${flat}</td></tr>
                            <tr><td>Интернет тариф</td><td>${internet}</td></tr>
                            <tr><td>Роутер</td><td>${router}</td></tr>
                            <tr><td>IPTV тариф</td><td>${tv}</td></tr>
                            <tr><td>Статический IP</td><td>${ip}</td></tr>
                         </table>`;

        if (phone) {
            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Готовы к заключению договора`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#confirmSuccessModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#failledModal').modal('show');
                }
            });
        }

        return false;
    });

    $('.confirm-question').click(function () {
        const textarea = $('.confirm-textarea');
        const currentValue = textarea.val();
        const newValue = currentValue ? `${currentValue} \n${$(this).text()}` : $(this).text();
        textarea.val(newValue).animate({ scrollTop : textarea[0].scrollHeight - textarea[0].clientHeight }, 100);
    });

    $('#formConfirmOrder').submit(function () {
        sendConfirmOrder(1);
        return false;
    });

    $('#btnNoConfirmOrder').click(function () {
        $('#notReadyModal').modal('show');
    });

    $('#btnCloseNotReadyModal').click(function () {
        $('#notReadyModal').modal('hide');
    });

    $('#btnAcceptNotReadyModal').click(function () {
        $('#notReadyModal').modal('hide');
        sendConfirmOrder(0);
    });

    function sendConfirmOrder(answer) {
        const order_id = $('input[name="order_id"]').val();
        const date = $('input[name="date"]').val();
        const reason = $('textarea[name="reason"]').val();
        $.ajax({
            method: 'POST',
            url: `/confirm-order?date=${date}&order_id=${order_id}`,
            data: `answer=${answer}&reason=${reason}`,
            success: () => {
                $('#confirmOrderSuccessModal').modal('show');
            },
            error: () => {
                $('#failledModal').modal('show');
            }
        });
    }

    $('.product-form, #productForm').submit(function() {
        const product = $(this).find('input[name="product"]').val();
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const name = $(this).find('input[name="name"]').val();

        if (testPhone(phone) && name) {
            const message = `<ul>
                                <li>Имя: ${name}</li>
                                <li>Товар: ${product}</li>
                             </ul>`;

            $('#productModal').modal('hide');

            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Запит на придбання мережевого обладнання`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#productSuccessModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });

    $('.home5-item').click(function () {
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
        } else {
            $(this).addClass('open');
        }
    });

    $('#homeForm').submit(function() {
        const phone = $(this).find('input[name="phone"]').val().slice(3);
        const name = $(this).find('input[name="name"]').val();

        if (testPhone(phone) && name) {
            const message = `<ul>
                                <li>Имя: ${name}</li>
                             </ul>`;

            $.ajax({
                method: 'POST',
                url: '/site/send-message',
                data: `Messages[phone]=${phone}&Messages[message]=${message}&Messages[subject]=Запит на підключення Інтернет для дому`,
                success: (res) => {
                    if (res) {
                        $(this).trigger('reset');
                        $('#homeSuccessModal').modal('show');
                    } else {
                        $('#failledModal').modal('show');
                    }
                },
                error: () => {
                    $('#failledModal').modal('show');
                }
            });
        }
        return false;
    });
});
